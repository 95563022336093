import React, { useCallback, useEffect, useState } from "react";
import queryString from "query-string";

import {
  BannerPage,
  ContainerContent,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  GalleryViewer,
  GalleryImageDisplay,
  InternalLink,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";

import { Row, Col, Radio } from "antd";

const Photos = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allHomeDesignsJson {
        nodes {
          slug
          images {
            tags
            imagePath {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const homes = data.allHomeDesignsJson.nodes;

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [tagsFilters, setTagsFilters] = useState([]);

  const isTagSelected = useCallback(
    tags => {
      if (selectedFilter === "all") return true;
      else if (
        tags &&
        tags.length > 0 &&
        tags.join(" ").indexOf(selectedFilter) >= 0
      )
        return true;
      else return false;
    },
    [selectedFilter]
  );
  useEffect(() => {
    if (location && location.search) {
      const params = queryString.parse(location.search);
      if (params.filter) {
        setSelectedFilter(params.filter);
      }
    }
  }, [location, setSelectedFilter]);
  useEffect(() => {
    let tagsSet = new Set([
      "all",
      "facade",
      "kitchen",
      "living",
      "bedroom",
      "bathroom",
      "outdoor",
      "interior",
      "study",
      "laundry",
    ]);
    homes.forEach(h =>
      h.images.forEach(i => i.tags.forEach(t => tagsSet.add(t)))
    );
    let filters = [];
    tagsSet.forEach(ts => {
      filters.push({ label: ts.toUpperCase(), value: ts });
    });

    setTagsFilters(filters);
  }, [homes]);

  const onChangeFilters = useCallback(
    event => {
      setSelectedFilter(event.target.value);
    },
    [setSelectedFilter]
  );
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    let filteredImages = [];
    homes.forEach(home => {
      home.images.forEach(img => {
        if (selectedFilter === "all" || isTagSelected(img.tags)) {
          filteredImages.push({
            ...img,
            homeSlug: home.slug,
          });
        }
      });
    });
    setFilteredImages(filteredImages);
  }, [isTagSelected, setFilteredImages, homes, selectedFilter]);

  const onClickImage = useCallback(
    idx => () => {
      setSelectedImageIndex(idx);
    },
    [setSelectedImageIndex]
  );

  const onModalClose = useCallback(() => {
    setSelectedImageIndex(null);
  }, [setSelectedImageIndex]);

  return (
    <Layout>
      <SEO
        title="Photos Gallery"
        description={"Browse through our work on Custom homes"}
      />
      <BannerPage
        title={"Photos Gallery"}
        subTitle={"Browse through our work on Custom homes"}
      />

      <ContainerContent>
        <div
          style={{
            padding: "1rem 0",
            marginBottom: "16px",
            textAlign: "center",
          }}
        >
          <Radio.Group
            onChange={onChangeFilters}
            value={selectedFilter}
            buttonStyle={"solid"}
          >
            {tagsFilters.map(tag => (
              <Radio.Button key={tag.value} value={tag.value}>
                {tag.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <Row gutter={[8, 8]}>
          {filteredImages.map((fi, idx) => (
            <Col lg={8} md={12} sm={12} xs={24} key={`${fi.imagePath}-${idx}`}>
              <GalleryImageDisplay
                onClick={onClickImage(idx)}
                imageFluid={fi.imagePath.childImageSharp.fluid}
              />
            </Col>
          ))}
          <GalleryViewer
            images={filteredImages}
            selectedImageIndex={selectedImageIndex}
            onModalClose={onModalClose}
          />
        </Row>
        <section style={{ textAlign: "center" }}>
          <InternalLink url={InternalLinkTypes.Disclaimer}>
            See our disclaimer
          </InternalLink>
        </section>
      </ContainerContent>
      <ContainerCta
        title={"Build your dream home with us"}
        primaryText={"Contact us"}
        primaryLink={InternalLinkTypes.Contact}
        secondaryLink={InternalLinkTypes.VideosGallery}
        secondaryText={"See Videos Gallery"}
      />
    </Layout>
  );
};

export default Photos;
